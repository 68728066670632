<template>
  <div>
    <div class="search-bar">
      <v-select
        :items="type"
        v-model="reportType"
        placeholder="Выберите тип отчета"
        height="48"
        hide-details
        outlined
        dense
        item-text="name"
        item-value="id"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="filter.dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            placeholder="Заезд - выезд"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            height="48"
            v-on="on"
            hide-details
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filter.dates"
          :first-day-of-week="1"
          no-title
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Отмена</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(filter.dates)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-select
        :items="dateTypes"
        v-model="dateType"
        placeholder="Выберите тип даты"
        height="48"
        hide-details
        outlined
        dense
        item-text="name"
        item-value="id"
      ></v-select>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        color="primary add-btn"
        class="ml-auto d-block"
        height="48"
        width="200"
        @click="getList"
      >
        Сформировать
      </v-btn>
      <v-btn
        color="primary add-btn"
        class="ml-3"
        height="48"
        width="200"
        @click="download"
      >
        Скачать
      </v-btn>
    </div>
    <v-text-field
      v-model="search"
      label="Поиск"
      class="mx-4"
      @change="getList"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="list.results"
      item-key="name"
      class="elevation-1"
      :search="search"
      v-if="tableStatus"
      :options.sync="options"
      :server-items-length="list.count"
    >
      <template v-slot:[`item.service_type`]="{ item }">
        {{ item.service_type | convertService }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { reportService } from "../../services/report.service";
import pagination from "@/utils/pagination";
import axios from "axios";
export default {
  mixins: [pagination],
  data() {
    return {
      type: ["По курортам", "По турагенту"],
      reportType: "",
      dateTypes: [
        {
          name: "По дате создания",
          id: "by_creation_date",
        },
        {
          name: "По дате брони",
          id: "by_order_date",
        },
      ],
      dateType: "",
      menu: false,
      filter: {
        dates: [],
      },
      search: "",
      list: [],
      tableStatus: false,
    };
  },
  watch: {
    reportType() {
      this.tableStatus = false;
    },
  },
  computed: {
    dateRangeText() {
      if (
        this.filter.dates.length == 2 &&
        this.filter.dates[0] > this.filter.dates[1]
      ) {
        this.filter.dates.reverse();
      }
      return this.filter.dates.join(" ~ ");
    },
    headers() {
      const baseHeaders = [
        { text: "Дата", value: "created_at" },
        { text: "Номер заявки", value: "id" },
        { text: "Сумма", value: "total_price" },
      ];
      switch (this.reportType) {
        case "По турагенту":
          return [
            ...baseHeaders,
            { text: "Туроператор", value: "tour_operator_name" },
            { text: "Турагент", value: "tour_agent_name" },
            { text: "Услуга", value: "service_type" },
          ];
        case "По курортам":
          return [
            ...baseHeaders,
            { text: "Местность", value: "location" },
            { text: "Зона отдыха", value: "hotel" },
            { text: "Турагент/туроператор", value: "company_name" },
            { text: "Номер", value: "category_name" },
          ];
        default:
          return baseHeaders;
      }
    },
  },
  filters: {
    convertService(value) {
      let service_types = {
        bus_orders: "Автобус",
        accommodation_orders: "Проживание",
        tour_orders: "Тур",
        railway_orders: "ЖД",
      };
      return service_types[value];
    },
  },
  methods: {
    secondsToDate(seconds) {
      let temp = new Date(1970, 0, 1); // Epoch
      temp.setSeconds(seconds);
      return temp;
    },
    dateFormating(arr, type) {
      arr.forEach((item) => {
        const formatDate = (timestamp) =>
          this.$moment(this.secondsToDate(timestamp)).format("YYYY-MM-DD");

        if (item.check_in && item.check_out) {
          item.checkInOut = `${formatDate(item.check_in)} ~ ${formatDate(
            item.check_out
          )}`;
        }

        item.created_at = formatDate(item.created_at);

        if (type === "Ж/Д") {
          item.route = `${item.train_number}/${item.route}`;
        }
      });
    },
    async getList() {
      const dates = this.dateRangeText.split("~").map((k) => k.trim());
      if (dates.length != 2) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Выберите время!",
          color: "error",
        });
        return;
      }

      let params = {
        from_date: new Date(dates[0]).getTime() / 1000,
        to_date: new Date(dates[1]).getTime() / 1000,
        date_filter_type: this.dateType,
        hotel: this.search,
        company: this.search,
        page: this.options.page,
        size: this.options.itemsPerPage,
      };

      try {
        this.$loading(true);
        switch (this.reportType) {
          case "По курортам":
            this.list = await reportService.getAdminReportHotels(params);
            break;
          case "По турагенту":
            this.list = await reportService.getAdminReportCompanies(params);
            break;
        }
        this.dateFormating(this.list.results);
        this.tableStatus = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.$loading(false);
      }
    },
    async download() {
      if (!this.reportType) return;

      const dates = this.dateRangeText.split("~").map((k) => k.trim());
      if (dates.length != 2) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Выберите время!",
          color: "error",
        });
        return;
      }

      let params = {
        from_date: new Date(dates[0]).getTime() / 1000,
        to_date: new Date(dates[1]).getTime() / 1000,
        date_filter_type: this.dateType,
        hotel: this.search,
        company: this.search,
      };

      const urls = {
        "По турагенту": "/report-admin/companies/export/",
        "По курортам": "/report-admin/hotel/export/",
      };

      try {
        const path = urls[this.reportType];
        const res = await axios.get(path, { params, responseType: "blob" });
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        if (typeof link.download === "undefined") {
          link.setAttribute("target", "_blank");
        }
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      } catch (error) {
        console.error("Error during file download", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
</style>